<template>
	<div>
		<form method='POST' enctype="multipart/form-data" action=''>
			<div class="form-title">Upload New Course</div>
			<div class='form-item'>
				<label class='form-label'>Title</label>
				<input class='form-input' v-model="this.title" type='text' >    
			</div>
			<div class='form-item'>
				<label class='form-label'>Description</label>
				<textarea class='form-input' v-model="this.description" >  </textarea> 
			</div>
			<div class='form-item'>
				<label class='form-label'>Password</label>
				<input 
				class='form-input' 
				v-model="this.password"
				type='password' >   
			</div>
			<div class='form-item'>
				<label class='form-label'>Banner Picture</label>
				<input class='form-input' @change="bannerFileHandler($event)" type="file" >   
			</div>
			<div class='form-item'>
				<label class='form-label'>Duration</label>
				<input class='form-input' v-model="this.duration" type="text" >   
			</div>
			<div class='form-button'>
				<button 
				type='submit'
				@click.prevent="createNewCourse()">Create Course</button>
				<button @click.prevent="closePopup()" >Cancel</button> 
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			title: '',
			description: '',
			password: '',
			bannerFile: null,
			duration: ''
		}
	},
	emits: [
		'closePopupEvent'
	],
	methods: {
			bannerFileHandler(event) {
                this.bannerFile = event.target.files[0];
            },
			createNewCourse() {
				var accessToken = window.sessionStorage.getItem('accessToken');
                var courseData = {
                    "title": this.title,
                    "description": this.description,
                    "password": this.password,
                    "bannerFile": this.bannerFile,
					"duration": this.duration
                };

                axios.post(`https://api.0618.io/api/newCourse`,
                courseData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }).then(() => {
                        this.closePopup();
                }).catch(error => {
					if (error.response) {
						let errorMessage = '';
						if(error.response.data.data['title']) {
							errorMessage += error.response.data.data['title'];
							errorMessage += '\n';
						}
						if(error.response.data.data['description']) {
							errorMessage += error.response.data.data['description'];
							errorMessage += '\n';
						}
						
						if(error.response.data.data['password']) {
							errorMessage += error.response.data.data['password'];
							errorMessage += '\n';
						} 

						if(error.response.data.data['bannerFile']) {
							errorMessage += error.response.data.data['bannerFile'];
							errorMessage += '\n';
						} 

						if(error.response.data.data['duration']) {
							errorMessage += error.response.data.data['duration'];
							errorMessage += '\n';
						} 
						alert(errorMessage);
					}
                });
			},
			closePopup() {
				this.$emit('closePopupEvent');
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.popup div {
		display: flex;
		flex-wrap: wrap;
		align-items: start;
	}

	.form-title {
		height: 50px;
		line-height: 50px;
		font-size: larger;
		justify-content: center;
	}

	.form-item {
		display: flex;
		padding-left: 15px;
		justify-content: start;
		align-items: start;
	}

	.form-button {
		justify-content: center;
	}

	.form-label {
		width: 20%;
		padding: 2px;
		margin: 10px 5px 5px;
		color: rgb(37 88 56);
		display: flex;
		flex-wrap: wrap;
		text-wrap: wrap;
	}

	.form-input {
		width: 70%;
		padding: 2px;
		margin: 5px;
		border: 1px solid rgb(124 188 184);
	}

	input.form-input {
		height: 30px;
	}

	textarea.form-input {
		height: 70px;
	}

	.form-button > button {
		height: 25px;
		width: 75px;
		min-width: fit-content;
		margin: 30px 10px 15px;
		background-color: rgb(104 163 158);
		color: white;
		border: none;
		border-radius: 2px;
	}

	.formFooter {
		font-size: small;
		font-style: italic;
	}
</style>
