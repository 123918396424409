<template>
	<div>
		<form method='POST' enctype="multipart/form-data" action=''>
			<div class="form-title">Upload Course Content</div>
			<div class="form-item">
				<label class='form-label'>Content Type</label>
				<select class="form-input" v-model="this.contentType">
					<option value="1">Text</option>
					<option value="2">Image</option>
					<option value="3">Video</option>
					<option value="4">PDF File</option>
				</select>
			</div>
			<div 
			class='form-item'
			v-if="this.contentType!='4'">
				<label class='form-label'>Text</label>
				<textarea class='form-input' v-model="this.contentText" ></textarea>   
			</div>
			<div 
			class='form-item'
			v-if="this.contentType=='4'">
				<label class='form-label'>File Label</label>
				<input class='form-input' v-model="this.contentText" >  
			</div>
			<div 
			class='form-item'
			v-if="this.contentType!='1'">
				<label v-if="this.contentType=='2'" class='form-label'>Image File</label>
				<label v-else-if="this.contentType=='3'" class='form-label'>Video File</label>
				<label v-else-if="this.contentType=='4'" class='form-label'>PDF File</label>
				<input v-if="this.contentType=='2'" class='form-input file_input' @change="this.contentFileHandler($event)" type="file" accept="image/*">   
				<input v-else-if="this.contentType=='3'" class='form-input file_input' @change="this.contentFileHandler($event)" type="file" accept="video/*">
				<input v-else-if="this.contentType=='4'" class='form-input file_input' @change="this.contentFileHandler($event)" type="file" accept="application/pdf">   
			</div>
			<div class='form-item'>
				<label class='form-label'>Serial</label>
				<input 
				class='form-input' 
				v-model="this.contentSerial"
				type='number' >   
			</div>
			<p
			v-if="this.progressText" 
			class="progressText">
				{{ this.progressText }}
			</p>
			<div class='form-button'>
				<button 
				type='submit'
				@click.prevent="this.createCourseContent()">Create Course Content</button>
				<button @click.prevent="this.closePopup()" >Close</button> 
			</div>
		</form>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			contentType: '1',
			contentText: '',
			contentFile: null,
			fileName: '',
			contentSerial: '',
			newChunk: true,
			chunks: [],
			progressText: ''
		}
	},
	props: ['popupData'],
	emits: [
		'closePopupEvent'
	],
	methods: {
			contentFileHandler(event) {
                this.contentFile = event.target.files[0];
				this.fileName = this.contentFile.name;
            }, 

			async createCourseContent() {
				this.chunks = [];
				this.newChunk = 1;
				this.progressText = '';

				let initialValidationMessage = '';

				if(this.contentType != 1 && !this.contentFile) {
					initialValidationMessage += 'Please add content file.\n';
				}

				if(this.contentType == 1 && !this.contentText) {
					initialValidationMessage += 'Please add content text.\n';
				}

				if(!this.contentSerial) {
					initialValidationMessage += 'Please add content serial.\n';
				}

				if(initialValidationMessage.length > 0) {
					alert(initialValidationMessage);
					return;
				}

				if(this.contentType != 1 && this.contentFile) this.createChunks();

				var accessToken = window.sessionStorage.getItem('accessToken');

				for (let i = 0; i < this.chunks.length || this.contentType == 1; i++) {
					var contentData = {
						"courseId": this.popupData.courseId,
						"contentType": this.contentType,
						"contentText": this.contentText,
						"contentFile": this.chunks[i],
						"contentSerial": this.contentSerial,
						"newChunk": this.newChunk,
						"fileName": this.fileName,
						"isLast": i == (this.chunks.length - 1) ? 1 : 0
					};

					if(this.contentType!=1) {
						this.progressText = `Progress: ${i + 1} / ${this.chunks.length}. Please don't close the popup before upload is done`;
					}

					await axios.post(`https://api.0618.io/api/addCourseContent`,
						contentData,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
								'Authorization': `Bearer ${accessToken}`
							}
						}).then(() => {
							this.newChunk = 0;
							if(i == this.chunks.length - 1 || this.contentType == 1) {
								alert('Content Uploaded Successfully');
								this.clearContents();
							}
						}).catch(error => {
							if (error.response) {
								let errorMessage = '';
								if(error.response.data.data['contentSerial']) {
									errorMessage += error.response.data.data['contentSerial'];
									errorMessage += '\n';
								}

								alert(errorMessage);
							}
						});

						if(this.contentType == 1) break;
				}
			},
			clearContents() {
				this.contentText = '';
				this.contentFile = null;
				this.fileName = '';
				this.contentSerial = '';
				this.newChunk = true;
				this.chunks = [];
				this.progressText = '';

				var fileInputElement = document.querySelectorAll('.file_input');
				fileInputElement[0].value='';
			},
			closePopup() {
				this.$emit('closePopupEvent');
			},
			createChunks() {
				let size = 1024 * 2 * 1000, chunkSize = Math.ceil(this.contentFile.size / size)

				for (let i = 0; i < chunkSize; i++) {
					this.chunks.push(this.contentFile.slice(
						i * size, Math.min(i * size + size, this.contentFile.size), this.contentFile.type
					));
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.popup div {
		display: flex;
		flex-wrap: wrap;
		align-items: start;
	}

	.form-title {
		height: 50px;
		line-height: 50px;
		font-size: larger;
		justify-content: center;
	}

	.form-item {
		display: flex;
		padding-left: 15px;
		justify-content: start;
		align-items: start;
	}

	.form-button {
		justify-content: center;
	}

	.form-label {
		width: 20%;
		padding: 2px;
		margin: 10px 5px 5px;
		color: rgb(37 88 56);
		display: flex;
		flex-wrap: wrap;
		text-wrap: wrap;
	}

	.form-input {
		height: 35px;
		width: 70%;
		padding: 2px;
		margin: 5px;
		border: 1px solid rgb(124 188 184);
	}

	input.form-input {
		height: 30px;
	}

	textarea.form-input {
		height: 70px;
	}

	.form-button > button {
		height: 25px;
		width: 75px;
		min-width: fit-content;
		margin: 30px 10px 15px;
		background-color: rgb(104 163 158);
		color: white;
		border: none;
		border-radius: 2px;
	}

	.formFooter {
		font-size: small;
		font-style: italic;
	}

	.progressText {
		width: 90%;
		padding: 20px;
		text-wrap: wrap;
		text-align: center;
		color: purple;
	}
</style>
