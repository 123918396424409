<template>
	<div id="menuBar">
		<span 
		class="menuItem"
		:class="this.activePopup==''?'active':''"
		>Course List</span>
		<span 
		class="menuItem"
		@click.prevent="this.$emit('changePopupForm', 'new-content-form')"
		:class="this.activePopup=='new-content-form'?'active':''"
		>New Course</span>
		<span 
		class="menuItem"
		@click.prevent="this.$emit('changePopupForm', 'register')"
		:class="this.activePopup=='register'?'active':''"
		>Register</span>
	</div>
</template>

<script>
export default {
	emits: ['changePopupForm'],
	props: ['activePopup']
}
</script>

<style scoped>
#menuBar {
	margin: 15px;
}

.menuItem {
	padding: 10px;
	margin: 5px 1px;
	border-bottom: 2px solid rgb(85, 108, 108);
}

.menuItem:hover {
	cursor: pointer;
	border-bottom: 2px solid burlywood;
}

.active {
	border-bottom: 2px solid burlywood;
}
</style>