<template>
	<div id="popup">
		<LoginComponent
		v-if="$props.activePopup=='login'"
		@closePopupEvent="this.$emit('closePopupEvent')"
		/>
		<RegistrationComponent 
		v-else-if="$props.activePopup=='register'"
		@closePopupEvent="this.$emit('closePopupEvent')"
		/>
		<NewContentForm
		v-else-if="$props.activePopup=='new-content-form'"
		@closePopupEvent="this.$emit('closePopupEvent')"
		/>
		<NewCourseMaterialForm
		v-else-if="$props.activePopup=='add-course-material-form'"
		:popupData="this.popupData"
		@closePopupEvent="this.$emit('closePopupEvent')"
		/>
	</div>
</template>

<script>
	import LoginComponent from './components/LoginComponent.vue'
	import RegistrationComponent from './components/RegistrationComponent.vue';
	import NewContentForm from './components/NewContentForm.vue';
	import NewCourseMaterialForm from './components/NewCourseMaterialForm.vue';

	export default {
		name: 'PopupPage',
		props: ['activePopup', 'popupData'],
		components: {
    LoginComponent: LoginComponent,
    RegistrationComponent: RegistrationComponent,
    NewContentForm: NewContentForm,
    NewCourseMaterialForm: NewCourseMaterialForm
},
		emits: ['closePopupEvent']
	}
</script>
