<template>
	<div id="headCont">
		<div id="headSection">
			<h2>Content Management System</h2>
		</div>
		<MenuBar
		v-if="!this.showPopup"
		:activePopup="this.popupForm"
		@changePopupForm="this.changePopupForm($event)"></MenuBar>
	</div>
	<div 
	id="popupSection"
	v-if="this.showPopup">
		<PopupPage
		v-bind:key="this.popupForm"
		:activePopup="this.popupForm"
		:popupData="this.popupData"
		@closePopupEvent="this.closePopup()"
		id="popup"/>
	</div>
	<div 
	id="mainSection"
	v-if="!this.showPopup">
		<ContentListPage
		@changePopupFormWithData="this.changePopupFormWithData($event)"></ContentListPage>
	</div>
</template>

<script>
	import PopupPage from './PopupPage.vue';
	import MenuBar from "./components/MenuBarComponent.vue";
	import ContentListPage from './ContentListPage.vue';

	export default {
		name: 'App',
		data() {
			return {
				showPopup: window.sessionStorage.getItem('accessToken') ? false : true,
				popupData: {},
				popupForm: window.sessionStorage.getItem('accessToken') ? '' : 'login',
			}
		},
		components: {
			PopupPage: PopupPage,
			ContentListPage: ContentListPage,
			MenuBar: MenuBar
		},
		methods: {
			changePopupForm(newPopup) {
				this.popupForm = newPopup;
				this.showPopup = true;
			},
			changePopupFormWithData(popupObj) {
				this.popupForm = popupObj.newPopup;
				this.popupData = popupObj.data;
				this.showPopup = true;
			},
			closePopup() {
				this.showPopup = false;
				this.popupForm = '';
				this.popupData = {};
			}
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin: 0;
		min-height: 100dvh;
		width: 100dvw;
		background-color: whitesmoke;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#popupSection {
		height: 60%;
		width: 60%;
		margin-top: 20px;
	}

	#popup {
		background-color: #fcf8f4;
		box-shadow: 10px 10px 5px 5px #c8c9ca;
		border: 1px solid #c8c9ca;
		overflow: auto;
	}

	#mainSection {
		padding: 5px;
		margin: 5px 0px 10px;
		min-width: 90%;
	}

	#headCont {
		width: 100%;
	}

	#headSection {
		height: 80px;
		width: 100%;
		background-color: black;
		color: beige;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
