<template>
	<div>
		<form method='POST' enctype="multipart/form-data" action=''>
			<div class="form-title">User Registration</div>
				<div class='form-item'>
					<label class='form-label'>Name</label>
					<input 
					class='form-input' 
					type='text' 
					v-model="userNameInput"
					id='name' 
					name='name'>    
				</div>
				<div class='form-item'>
					<label class='form-label'>Email</label>
					<input 
					class='form-input' 
					type='text' 
					id='email'
					v-model="userEmailInput" 
					name='email'>    
				</div>
				<div class='form-item'>
					<label class='form-label'>Password</label>
					<input 
					class='form-input' 
					type='password' 
					id='password' 
					v-model="userPasswordInput"
					name='password'>   
				</div>
				<div class='form-button'>
					<button 
					type='submit'
					@click.prevent="registerUser()">Submit</button>
					<button @click.prevent="closeRegistrationPopup()" >Close</button> 
				</div>
			</form>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			userNameInput: '',
			userEmailInput: '',
			userPasswordInput: ''
		}
	},
	emits: [
		'closePopupEvent'
	],
	methods: {
			registerUser() {
				var accessToken = window.sessionStorage.getItem('accessToken');
				var userData = {
					"name": this.userNameInput,
					"email": this.userEmailInput,
					"password": this.userPasswordInput
				};
			
				axios.post(
					`https://api.0618.io/api/register`,
					userData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': `Bearer ${accessToken}`
						}
					}
				).then(() => {
					alert('Registration Done Successfully');
					this.closeRegistrationPopup();
				}).catch(
					(error) => {
						if (error.response) {
							let errorMessage = '';
							if(error.response.data.error['name']) {
								errorMessage += error.response.data.error['name'];
								errorMessage += '\n';
							}
							if(error.response.data.error['email']) {
								errorMessage += error.response.data.error['email'];
								errorMessage += '\n';
							}
							
							if(error.response.data.error['password']) {
								errorMessage += error.response.data.error['password'];
								errorMessage += '\n';
							} 
							alert(errorMessage);
						}
						console.log('Error in Registration: ' + error);
					}
				);
			},
			closeRegistrationPopup() {
				this.$emit('closePopupEvent');
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.popup div {
		display: flex;
		flex-wrap: wrap;
		align-items: start;
	}

	.form-title {
		height: 50px;
		line-height: 50px;
		font-size: larger;
		justify-content: center;
	}

	.form-item {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.form-button {
		justify-content: center;
	}

	.form-label {
		min-width: fit-content;
		width: 20%;
		padding: 2px;
		margin: 5px;
		color: rgb(37 88 56);
		display: flex;
	}

	.form-input {
		min-width: 300px;
		padding: 10px;
		margin: 10px;
		border: 1px solid rgb(124 188 184);
	}

	.form-button > button {
		height: 25px;
		width: 75px;
		min-width: fit-content;
		margin: 30px 10px 15px;
		background-color: rgb(104 163 158);
		color: white;
		border: none;
		border-radius: 2px;
	}
</style>
