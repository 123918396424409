<template>
	<table>
		<thead>
			<tr>
				<th>#</th>
				<th style="width: 150px;">Banner</th>
				<th style="width: 150px;">Course Title</th>
				<th style="width: 500px;">Description</th>
				<th style="width: 100px;">Duration</th>
				<th  style="width: 300px;">Action</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(item, index) in this.courseList"
			v-bind:key="item.id">
				<td>{{ index + 1 }}</td>
				<td><img v-bind:src="`https://api.0618.io${item.banner_url}`" v-bind:alt="item.title" id='recipe-detail-page-image' width="350" height="220"></td>
				<td style="text-align: start;">{{ item.title }}</td>
				<td style="text-align: justify;">{{ item.description }}</td>
				<td>{{ item.duration }}</td>
				<td><button
					@click.prevent="this.showNewContentPopup(item.id)"
					>Add Course Material</button></td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import axios from "axios";

export default {
	mounted() {
		this.getCourseList();
	},
	data() {
		return {
			courseList: []
		}
	},
	emits: ['changePopupFormWithData'],
	methods: {
		getCourseList() {
			var accessToken = window.sessionStorage.getItem('accessToken');

			axios.get(
				`https://api.0618.io/api/courseList`,
				{
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				}
			).then((response) => {
				this.courseList = response.data.data;
			}).catch(
				(error) => {
					if (error.response) {
						alert(error.response.data.error);
					}
					console.log('Error: ' + error);
				}
			);
		},
		showNewContentPopup(courseId) {
			var emitObj = {
				newPopup: 'add-course-material-form',
				data: {
					courseId: courseId
				}
			}

			this.$emit('changePopupFormWithData', emitObj);
		}
	}
}
</script>

<style scoped>
table {
	border-collapse: collapse;
}

td {
	height: 30px;
	padding: 5px;
}

thead>tr {
	margin-bottom: 4px;
	background-color: #fcf8f4;
	box-shadow: 1px 1px #c8c9ca;
	color: rgb(31, 58, 58);
	padding: 3px;
	font-weight: 200;
	height: 30px;
}

tbody>tr {
	border-bottom: 1px dotted rgb(209, 210, 212);
}

img {
	height: 50px;
	width: 50px;
}
</style>